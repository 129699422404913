import { stringify } from 'query-string';
import axios from 'axios';
import config from '../config/config';
import paramsToFormData from '../utils/paramsToFormData';
import {
  GET_LIST,
  GET_ONE,
  GET_MANY,
  GET_MANY_REFERENCE,
  UPDATE,
  CREATE,
  DELETE,
  HttpError
} from 'react-admin';

var jwt = require('jwt-simple');
const ENV = process.env.NODE_ENV || 'development';
const apiUrl = config[ENV].api.url;
const apiKey = config[ENV].api.api_key;
const jwtKey = config[ENV].jwt.key;
const localStorageAuth = config[ENV].local_storage.auth;

const provider = (type, resource, params) =>
{
  let url = '';

  const token = JSON.parse(jwt.decode(localStorage.getItem(jwt.encode(localStorageAuth, jwtKey)), jwtKey)).auth_token;
  const options =
  {
    headers:
    {
      'Accept': 'application/json',
      'Content-Type': 'multipart/form-data',
      'x-api-key': apiKey,
      'x-token': 'Bearer ' + token
    },
    data: paramsToFormData(params.data)
  };

  switch (type)
  {
    case GET_LIST:
    {
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      const query =
      {
        sort: JSON.stringify([field, order]),
        range: JSON.stringify([
          (page - 1) * perPage,
          page * perPage - 1,
        ]),
        filter: JSON.stringify(params.filter)
      };

      if (resource === 'payment-link')
        url = `${apiUrl}/payment-link/list?${stringify(query)}`;
      else if (resource === 'payment-link-lead')
        url = `${apiUrl}/payment-link-lead/list?${stringify(query)}`;
      else
        url = `${apiUrl}/${resource}?${stringify(query)}`;

      options.url = url;

      break;
    }
    case GET_ONE:
    {
      if (resource === 'payment-link')
        url = `${apiUrl}/payment-link/byId/${params.id}`;
      else if (resource === 'payment-link-lead')
        url = `${apiUrl}/payment-link-lead/byId/${params.id}`;
      else
        url = `${apiUrl}/${resource}/${params.id}`;

      options.url = url;

      break;
    }
    case GET_MANY:
    {
      if (resource === 'payment-link')
        url = `${apiUrl}/payment-link/list`;
      else if (resource === 'payment-link-lead')
        url = `${apiUrl}/payment-link-lead/list`;
      else
        url = `${apiUrl}/${resource}`;

      options.url = url;

      break;
    }
    case GET_MANY_REFERENCE:
    {
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      const query =
      {
          sort: JSON.stringify([field, order]),
          range: JSON.stringify([
              (page - 1) * perPage,
              page * perPage - 1,
          ]),
          filter: JSON.stringify({
              ...params.filter,
              [params.target]: params.id,
          }),
      };

      url = `${apiUrl}/${resource}?${stringify(query)}`;

      options.url = url;

      break;
    }
    case UPDATE:
    {
      url = `${apiUrl}/${resource}/${params.id}`;

      options.method = 'PUT';
      options.url = url;

      break;
    }
    case CREATE:
    {
      url = `${apiUrl}/${resource}`;

      options.method = 'POST';
      options.url = url;

      break;
    }
    case DELETE:
    {
      url = `${apiUrl}/${resource}/${params.id}`;

      options.method = 'DELETE';
      options.url = url;

      break;
    }
    default:
      throw new Error(`Unsupported Data Provider request type ${type}`);
  }

  return axios(options)
    .then(res =>
    {
      return res.data;
    })
    .then(response =>
    {
      switch (type)
      {
        case GET_LIST:
        case GET_MANY:
          const data = response.data;
          const result = response.result;
          const meta = response.meta;

          return {
            data: data ? data : result,
            total: meta.total,
            pagination:
            {
              page: meta.page,
              perPage: meta.perpage
            }
          }
        case GET_MANY_REFERENCE:
          return {
            data: response.result,
            total: response.result.lenght
          }
        case GET_ONE:
          return {
            data: response.result
          }
        case UPDATE:
        case CREATE:
          return {
            data: response.result
          }
        default:
          return { data: [] }
      }
    }
  )
  .catch(error =>
  {
    if (error.response.status < 200 || error.response.status >= 300)
    {
      if (error.response.data && error.response.data.error)
      {
        return Promise.reject(
          new HttpError(error.response.data.error, error.response.status)
        );
      }
      else
      {
        return Promise.reject(
          new HttpError(error.response.statusText, error.response.status)
        );
      }
    }
  });
};

export default provider;
