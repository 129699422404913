import React from 'react';
import {
    Show,
    TextField,
    BooleanField,
    SimpleShowLayout
} from 'react-admin';

const PaymentLinkLeadShow = props => (
  <Show
    title="Lead" {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="name" label="Nome" sortable={false} />
      <TextField source="email" label="E-mail" sortable={false} />
      <TextField source="document" label="CPF" sortable={false} />
      <TextField source="phone" label="Telefone" sortable={false} />
      <TextField source="student_name" label="Nome Aluno" sortable={false} />
      <TextField source="student_birthdate" label="Nascimento Aluno" sortable={false} />
      <TextField source="created_at_format" label="Data" sortable={false} />
      <BooleanField source="purchase_completed" label="Concluída" sortable={false} />
    </SimpleShowLayout>
  </Show>
);

export default PaymentLinkLeadShow;
