import React from 'react';
import { Filter, TextInput, SelectInput } from 'react-admin';

const CustomFilterTextPurchaseCompleted = (props) => (
  <Filter {...props}>
    <TextInput label="Procurar..." source="q" alwaysOn />
    <SelectInput source="purchase_completed" label="Concluída?" choices={[
        { id: true, name: 'Compra Concluída' },
        { id: false, name: 'Compra Não Concluída' }
    ]} alwaysOn />
  </Filter>
)

export default CustomFilterTextPurchaseCompleted;
