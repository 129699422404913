import React, { Fragment } from 'react';
import config from '../../config/config';
import {
  required,
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  BooleanInput,
  SelectInput,
  FormDataConsumer
} from 'react-admin';

// own ui
import ValueField from '../../ui/value-field';
import { DateTimeInput } from '../../ui/date-pickers';

const ENV = process.env.NODE_ENV || 'development';
const data = config[ENV].data;

const validateName = [required('Digite o nome!')];
const validateType = [required('Selecione um tipo!')];
const validateProduct = [required('Selecione um produto!')];
const validateValueDiscount = [required('Digite o valor com desconto!')];
const validateNumberMaxInstallments = [required('Digite o número máximo de parcelas!')];
const validateVindiLink = [required('Digite o link do vindi!')];
const validateVindiJwtToken = [required('Digite o token JWT do vindi!')];
const optionRender = product => `${product.name} - ${product.payment_methods}`;

const validatePaymentLinkCreate = (values) =>
{
  const errors = {};

  if (!values.validity)
      errors.validity = ['Selecione a data de validade!'];

  return errors;
};

const PaymentLinkCreate = (props) => (
  <Create title="Novo Link de Pagamento" {...props}>
    <SimpleForm redirect="/payment-link" validate={validatePaymentLinkCreate}>
      <TextInput source="name" label="Nome" fullWidth={true} validate={validateName} />
      <SelectInput source="type" label="Tipo" fullWidth={true} choices={ data } validate={validateType} />
      <FormDataConsumer>
      {
        ({ formData, ...rest }) => formData.type &&
          <Fragment>
            <SelectInput source="product_store_id" label="Produto" fullWidth={true}
                choices={ data.find(type => type.id === formData.type).products }
                optionText={optionRender} validate={validateProduct} />
            <FormDataConsumer>
            {
              ({ formData, ...rest }) => formData.product_store_id &&
               formData.type === 1 ?
               <Fragment>
                 <ValueField />
                 <BooleanInput label="Com Desconto?" source="has_discount" />
                 <FormDataConsumer>
                 {
                   ({ formData, ...rest }) => formData.has_discount &&
                    <TextInput source="value_with_discount" label="Valor a ser Cobrado" fullWidth={true} validate={validateValueDiscount} />
                 }
                 </FormDataConsumer>
                 <NumberInput label="Número Máximo de Parcelas" source="number_max_installments" fullWidth={true} min={1} max={12} step={1} validate={validateNumberMaxInstallments} />
                 <DateTimeInput source="validity" label="Validade *" options={{ format: 'dd/MM/yyyy, HH:mm', ampm: false, clearable: false }} />
               </Fragment>
               : formData.product_store_id && formData.type === 2 ?
               <Fragment>
                 <ValueField />
                 <BooleanInput label="Com Desconto?" source="has_discount" />
                 <FormDataConsumer>
                 {
                   ({ formData, ...rest }) => formData.has_discount &&
                    <TextInput source="value_with_discount" label="Valor a ser Cobrado" fullWidth={true} validate={validateValueDiscount} />
                 }
                 </FormDataConsumer>
                 <DateTimeInput source="validity" label="Validade *" options={{ format: 'dd/MM/yyyy, HH:mm', ampm: false, clearable: false }} />
                 <br/>
                 <TextInput source="vindi_url" type="url" label="Link da Página de Pagamento do Vindi" fullWidth={true} validate={validateVindiLink} />
                 <br/>
                 <TextInput source="vindi_jwt_token" type="text" label="Token JWT da Página de Pagamento do Vindi" fullWidth={true} validate={validateVindiJwtToken} />
               </Fragment>
               : null
            }
            </FormDataConsumer>
          </Fragment>
      }
      </FormDataConsumer>
    </SimpleForm>
  </Create>
);

export default PaymentLinkCreate;
