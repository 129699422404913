import { downloadCSV } from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

const exporter = leads => {
    const data = leads.map(lead => ({
        id: lead.id,
        nome: lead.name,
        email: lead.email,
        cpf: lead.document,
        telefone: lead.phone,
        nome_aluno: lead.student_name,
        nascimento_aluno: lead.student_birthdate,
        concluida: (lead.purchase_completed ? 'Sim' : 'Não'),
        data: lead.created_at_format
    }));
    const csv = convertToCSV({
        data,
        fields: ['id', 'nome', 'email', 'cpf', 'telefone', 'nome_aluno',
                 'nascimento_aluno', 'concluida', 'data']
    });
    downloadCSV(csv, 'leads');
}

export default exporter;
