import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    BooleanField
} from 'react-admin';

// own ui
import CustomFilterTextPurchaseCompleted from '../../ui/custom-filter-text-purchase-completed';
import PaymentLinkLeadExporter from '../../exporter/payment-link-lead-exporter';

const PaymentLinkLeadList = (props) => (
  <List
    title="Leads" {...props}
    sort={{ field: 'id', order: 'desc' }}
    filters={ <CustomFilterTextPurchaseCompleted /> }
    filterDefaultValues={{ active: true }}
    bulkActionButtons={ false }
    exporter={ PaymentLinkLeadExporter } >
    <Datagrid
      rowClick="show">
      <TextField source="id" sortable={true} />
      <TextField source="name" label="Nome" sortable={false} />
      <TextField source="email" label="E-mail" sortable={false} />
      <TextField source="document" label="CPF" sortable={false} />
      <TextField source="phone" label="Telefone" sortable={false} />
      <TextField source="student_name" label="Nome Aluno" sortable={false} />
      <TextField source="student_birthdate" label="Nascimento Aluno" sortable={false} />
      <TextField source="created_at_format" label="Data" sortable={false} />
      <BooleanField source="purchase_completed" label="Concluída" sortable={false} />
    </Datagrid>
  </List>
);

export default PaymentLinkLeadList;
