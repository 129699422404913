import React from 'react';
import {
  required,
  Edit,
  TextInput,
  SimpleForm,
  BooleanInput
} from 'react-admin';

// own ui
import EditTitle from '../../ui/edit-title';
import CustomEditToolbar from '../../ui/custom-edit-toolbar';
import { DateTimeInput } from '../../ui/date-pickers';

const validateName = [required('Digite o nome!')];
const validateVindiLink = [required('Digite o link do vindi!')];
const validateVindiJwtToken = [required('Digite o token JWT do vindi!')];

const validatePaymentLinkEdit = (values) =>
{
  const errors = {};

  if (!values.validity)
      errors.validity = ['Selecione a data de validade!'];

  return errors;
};

const VindiField = ({record}) =>
{
  // IS VINDI
  if (record.number_max_installments === 0)
  {
    return (
      <div>
        <br/>
        <TextInput source="vindi_url" type="url" label="Link da Página de Pagamento do Vindi" fullWidth={true} validate={validateVindiLink} />
        <TextInput source="vindi_jwt_token" type="text" label="Token JWT da Página de Pagamento do Vindi" fullWidth={true} validate={validateVindiJwtToken} />
      </div>
    );
  }
  else
  {
    return (
      <div>
        <br/>
      </div>
    );
  }
};

const PaymentLinkEdit = (props) => (

  <Edit {...props} title={ <EditTitle subtitle="Editar Link de Pagamento: " value="name" /> }>
    <SimpleForm
      redirect="/payment-link"
      validate={validatePaymentLinkEdit}
      toolbar={<CustomEditToolbar />}>
      <TextInput source="id" label="Id" disabled />
      <TextInput source="product_name" label="Produto" fullWidth={true} disabled />
      <TextInput source="name" label="Nome" fullWidth={true} validate={validateName} />
      <DateTimeInput source="validity" label="Validade *" options={{ format: 'dd/MM/yyyy, HH:mm', ampm: false, clearable: false }} />
      <VindiField />
      <BooleanInput source="active" label="Ativo" />
    </SimpleForm>
  </Edit>
);

export default PaymentLinkEdit;
