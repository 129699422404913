import * as React from "react";
import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { MenuItemLink, DashboardMenuItem, usePermissions } from 'react-admin';
import SubMenu from './submenu';

// ICONS
import ListIcon from '@material-ui/icons/List';
import CreateIcon from '@material-ui/icons/Add';
import PaymentLinkIcon from '@material-ui/icons/Link';
import PaymentLinkLeadIcon from '@material-ui/icons/ShoppingCart';

const Menu: FC<Props> = ({ onMenuClick, dense }) => {
    const [state, setState] = useState({
        menuPaymentLink: false,
        menuPaymentLinkLead: false
    });

    const open = useSelector((state: AppState) => state.admin.ui.sidebarOpen);
    useSelector((state: AppState) => state.theme);

    const handleToggle = (menu) => {
        setState(state => ({ ...state, [menu]: !state[menu] }));
    };

    const { loaded } = usePermissions();

    return loaded ? (
        <div>
          <br />
          <DashboardMenuItem
            sidebarIsOpen={open} />

            <SubMenu
              handleToggle={() => handleToggle('menuPaymentLink')}
              isOpen={state.menuPaymentLink}
              sidebarIsOpen={open}
              sidebarInside={false}
              name="Links"
              icon={<PaymentLinkIcon />}
              dense={dense}
            >
              <MenuItemLink
                to="/payment-link"
                primaryText="Listar"
                leftIcon={<ListIcon />}
                onClick={onMenuClick} />
              <MenuItemLink
                to="/payment-link/create"
                primaryText="Criar"
                leftIcon={<CreateIcon />}
                onClick={onMenuClick} />
            </SubMenu>

            <SubMenu
              handleToggle={() => handleToggle('menuPaymentLinkLead')}
              isOpen={state.menuPaymentLinkLead}
              sidebarIsOpen={open}
              sidebarInside={false}
              name="Leads"
              icon={<PaymentLinkLeadIcon />}
              dense={dense}
            >
              <MenuItemLink
                to="/payment-link-lead"
                primaryText="Listar"
                leftIcon={<ListIcon />}
                onClick={onMenuClick} />
            </SubMenu>

        </div>
    ): null;
}

export default Menu;
