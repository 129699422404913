import React from 'react';
import {
    Show,
    TextField,
    BooleanField,
    SimpleShowLayout
} from 'react-admin';

// own ui
import LinkField from '../../ui/link-field';

const PaymentLinkShow = props => (
    <Show
      title="Link de Pagamento" {...props}>
        <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="name" label="Nome" />
            <TextField source="product_name" label="Produto" />
            <TextField source="value_format" label="Valor" />
            <BooleanField source="has_discount" label="Com Desconto?" />
            <TextField source="value_with_discount_format" label="Valor com Desconto" />
            <TextField source="validity_format" label="Validade" />
            <TextField source="vindi_url" label="Vindi Url" />
            <TextField source="vindi_jwt_token" label="Vindi Token JWT" />
            <LinkField source="payment_url" text="Ver" label="Url Pagamento" sortable={false}/>
        </SimpleShowLayout>
    </Show>
);

export default PaymentLinkShow;
