import React, { Fragment } from 'react';
import { useFormState } from 'react-final-form';
import config from '../config/config';

const ENV = process.env.NODE_ENV || 'development';
const data = config[ENV].data;

const ValueField = ({ record }) => {
  const { values } = useFormState();
  const product = data.find(type => type.id === values.type).products.find(product => product.id === values.product_store_id);
  return (
      <Fragment>
        <span>
          <b>Valor: { values.product_store_id && product != null ? product.value : '' }
          </b>
        </span>
        <br/>
        <br/>
      </Fragment>
  );
};

ValueField.defaultProps = {
    addLabel: true,
    source: 'Valor',
};

export default ValueField;
