import React from 'react';
import { Admin, Resource } from 'react-admin';

import Dashboard from './pages/dashboard';
import Login from './pages/login';
import Layout from './layout/layout';

import dataProvider from './provider/dataProvider';
import authProvider from './provider/authProvider';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import portugueseMessages from 'ra-language-portuguese';

// PAYMENT LINK
import PaymentLinkIcon from '@material-ui/icons/Link';
import PaymentLinkList from './container/payment-link/list';
import PaymentLinkCreate from './container/payment-link/create';
import PaymentLinkEdit from './container/payment-link/edit';
import PaymentLinkShow from './container/payment-link/show';

// PAYMENT LINK LEAD
import PaymentLinkLeadIcon from '@material-ui/icons/ShoppingCart';
import PaymentLinkLeadList from './container/payment-link-lead/list';
import PaymentLinkLeadShow from './container/payment-link-lead/show';

const i18nProvider = polyglotI18nProvider(locale => portugueseMessages);

const App = () => (
  <Admin
    title="Kidsa Link Pagamento - Admin"
    i18nProvider={i18nProvider}
    locale="pt"
    layout={Layout}
    loginPage={Login}
    dashboard={Dashboard}
    authProvider={authProvider}
    dataProvider={dataProvider}>
    {
      <Resource
        name =  "payment-link"
        icon = { PaymentLinkIcon }
        list = { PaymentLinkList }
        create = { PaymentLinkCreate }
        edit = { PaymentLinkEdit }
        show = { PaymentLinkShow }
      />
    }
    {
      <Resource
        name =  "payment-link-lead"
        icon = { PaymentLinkLeadIcon }
        list = { PaymentLinkLeadList }
        show = { PaymentLinkLeadShow }
      />
    }
  </Admin>
);

export default App;
