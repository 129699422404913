import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    NumberField,
    EditButton,
    BooleanField
} from 'react-admin';

// own ui
import CustomFilterTextActive from '../../ui/custom-filter-text-active';
import LinkField from '../../ui/link-field';

const PaymentLinkList = (props) => (
  <List
    title="Links de Pagamento" {...props}
    sort={{ field: 'id', order: 'desc' }}
    filters={ <CustomFilterTextActive /> }
    filterDefaultValues={{ active: true }}
    bulkActionButtons={ false }
    exporter={ false } >
    <Datagrid
      rowClick="show">
      <TextField source="id" sortable={true} />
      <TextField source="name" label="Nome" sortable={false} />
      <TextField source="product_name" label="Produto" sortable={false} />
      <TextField source="value_with_discount_format" label="Valor" sortable={false} />
      <NumberField source="number_max_installments" label="Parcelas" sortable={false} />
      <BooleanField source="has_discount" label="Tem Desconto" sortable={false} />
      <TextField source="validity_format" label="Validade" sortable={false} />
      <LinkField source="payment_url" text="Ver" label="Url Pagamento" sortable={false}/>
      <BooleanField source="active" label="Ativo" sortable={false} />
      <EditButton />
    </Datagrid>
  </List>
);

export default PaymentLinkList;
