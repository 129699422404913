import React from "react";
import get from "lodash/get";
import config from "../config/config";
const ENV = process.env.NODE_ENV || "development";
const payment_url = config[ENV].api.payment_url;

const styles = {
  main: {
    display: "flex",
    flexWrap: "wrap",
  },
};

const LinkField = ({ record, source, ...props }) => {
  const value = get(record, source);
  console.log(record.id);

  if (value !== null && value !== "") {
    return (
      <span style={styles.main}>
        {
          <a
            href={payment_url + `?plid=${record.id}`}
            target="_blank"
            rel="noopener noreferrer"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {props.text}
          </a>
        }
      </span>
    );
  } else return null;
};

LinkField.defaultProps = {
  addLabel: true,
  source: "Link",
};

export default LinkField;
